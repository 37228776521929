import { ThemeProvider } from '@mui/material'
import { ProtectedRoute } from '@thriveglobal/thrive-web-auth-core'
import { useIsFeatureEnabled } from '@thriveglobal/thrive-web-core'
import {
    ThriveFullscreenLoading,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React, { lazy, Suspense } from 'react'
import { BrowserRouter, Switch } from 'react-router-dom'
import { FeatureFlags } from '../constants/featureFlags'
import { ROUTES } from './routes'

const ScheduleResetOrMicroStepPage = lazy(
    () => import('../pages/oldHomePage/ScheduleResetOrMicroStepPage')
)
const DailyExperience = lazy(
    () => import('../pages/dailyExperience/DailyExperience')
)
const WelcomePage = lazy(() => import('../pages/welcome/Welcome'))
const OnBoardingPage = lazy(() => import('../pages/onBoarding'))

const Router: React.FC = () => {
    const theme = useTheme()

    const hasAccess = useIsFeatureEnabled(
        FeatureFlags.HasCoachingAccess,
        false,
        true
    )

    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <Suspense fallback={<ThriveFullscreenLoading />}>
                    <Switch>
                        <ProtectedRoute
                            path={ROUTES.OLD_HOMEPAGE}
                            component={ScheduleResetOrMicroStepPage}
                        />
                        <ProtectedRoute
                            path={ROUTES.WELCOME}
                            component={
                                hasAccess
                                    ? WelcomePage
                                    : ScheduleResetOrMicroStepPage
                            }
                        />
                        <ProtectedRoute
                            path={ROUTES.ONBOARDING}
                            component={
                                hasAccess
                                    ? OnBoardingPage
                                    : ScheduleResetOrMicroStepPage
                            }
                        />
                        <ProtectedRoute
                            path={ROUTES.COACHING}
                            component={
                                hasAccess
                                    ? DailyExperience
                                    : ScheduleResetOrMicroStepPage
                            }
                        />
                    </Switch>
                </Suspense>
            </BrowserRouter>
        </ThemeProvider>
    )
}

export default Router
