import { ComponentProps } from 'react'
import { ThriveApplication } from '@thriveglobal/thrive-web-core'
import { GetCoachesGqlResponse } from '../graphql/generated/autogenerated'

type ThriveInMemoryCacheConfig = Exclude<
    ComponentProps<typeof ThriveApplication>['apolloClientOptions'],
    undefined
>['cacheOptions']

export const apolloCacheOptions: ThriveInMemoryCacheConfig = {
    typePolicies: {
        CoachesQuery: {
            merge: true,
            fields: {
                getAll: {
                    keyArgs: ['pageSize'],

                    merge(
                        existing: GetCoachesGqlResponse | undefined,
                        incoming: GetCoachesGqlResponse
                    ) {
                        return existing &&
                            incoming?.__typename === 'GetCoachesSuccess' &&
                            incoming?.page !== 1
                            ? {
                                  ...incoming,
                                  result: [
                                      ...(existing?.__typename ===
                                      'GetCoachesSuccess'
                                          ? existing.result
                                          : []),
                                      ...incoming.result
                                  ]
                              }
                            : incoming
                    }
                }
            }
        }
    }
}
