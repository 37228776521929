export enum ROUTES {
    COACHING = '/coaching',
    WELCOME = '/coaching/welcome',
    ONBOARDING = '/coaching/onboarding',
    DOES_NOT_EXIST = '/does-not-exist',

    // admin
    UPLOAD_COACHEES = '/coaching/a/upload-coachees',

    //old coaching home page
    OLD_HOMEPAGE = '/coaching/schedule'
}
